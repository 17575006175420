/* eslint-disable @typescript-eslint/no-explicit-any */

import { Logger } from "~/utils/logger/browser";

import { EvergageActions } from "./actions";
import { EvergageContentZones, EvergagePage } from "./types";

const pageSelector = (page: string) => `[data-evergage-page="${page}"]`;
const zoneSelector = (zone: string) => `[data-evergage-zone="${zone}"]`;
const categoriesSelector = '[data-evergage="breadcrumbs"] a';
const categoryLinkAttribute = "data-evergage-breadcrumbs-link";

const getCategories = (evergage: any, startFrom: number | null = null) =>
  evergage.resolvers.buildCategoryIdAttribute(
    categoriesSelector,
    categoryLinkAttribute,
    startFrom,
    null,
    (categoryId: any) => [categoryId.toUpperCase()],
  );

const getCategoriesFromJsonLd = (evergage: any) => {
  const categories = evergage.resolvers.fromJsonLd(null, (data: any) =>
    data?.itemListElement
      ?.sort(
        (
          { position: pos1 }: { position: any },
          { position: pos2 }: { position: any },
        ) => pos1 - pos2,
      )
      ?.map(({ name }: { name: any }) => name.toUpperCase()),
  )();

  if (categories) {
    return categories;
  }

  Logger.getLogger().error("Categories not found in JsonLd");
  return [];
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const evergageSitemapConfig = (evergage: any) => ({
  global: {
    /*
    The user email added to each event before sending it to Personalization https://epa.ms/XlxHg
    About user identity mapping see https://epa.ms/WWeFg
*/
    onActionEvent: (actionEvent: any) => {
      const email = evergage
        .cashDom("[data-evergage-email]")
        .attr("data-evergage-email");
      const userId = evergage
        .cashDom("[data-evergage-user-id]")
        .attr("data-evergage-user-id");
      if (email && userId) {
        actionEvent.user = actionEvent.user || {};
        actionEvent.user.attributes = actionEvent.user.attributes || {};
        actionEvent.user.attributes.emailAddress = email;
        actionEvent.user.attributes.sfmcContactKey = userId;
      }
      return actionEvent;
    },
    contentZones: [{ name: "global_popup" }],
  },
  pageTypeDefault: {
    name: "default",
  },
  pageTypes: [
    {
      name: "home_page",
      action: EvergageActions.HOME_PAGE_VIEW,
      isMatch: () => location.pathname.split("/").filter((val) => val).length <= 1,
      contentZones: [
        {
          name: "home_hero_banner",
          selector: zoneSelector(EvergageContentZones.HOME_HERO_BANNER),
        },
      ],
    },
    {
      name: "product_detail",
      action: EvergageActions.PRODUCT_DETAILS,
      isMatch: () => location.pathname.split("/").includes("p"),
      WebConsent: true,
      contentZones: [
        {
          name: "similar_items",
          selector: zoneSelector(EvergageContentZones.CROSS_SELL),
        },
        {
          name: "footer_banner",
          selector: zoneSelector(EvergageContentZones.GENERIC_BANNER),
        },
      ],
      catalog: {
        Product: {
          _id: () => {
            // waiting for product to be available on the page https://epa.ms/XnK5w
            return evergage.util.resolveWhenTrue.bind(() => {
              const productId = evergage
                .cashDom("[data-evergage-product-id]")
                .attr("data-evergage-product-id");
              if (productId) {
                return productId;
              } else {
                return false;
              }
            });
          },
          name: evergage
            .cashDom("[data-evergage-product-name]")
            .attr("data-evergage-product-name"),
          description: evergage
            .cashDom("[data-evergage-product-description]")
            .attr("data-evergage-product-description"),
          primaryCategory: evergage
            .cashDom('[data-testautomation-id="breadcrumbs-pannel"] a')
            .eq(0)
            .attr("data-evergage-breadcrumbs-link"),
          subCategory: getCategories(evergage, 1),
          categories: getCategories(evergage),
          url: evergage.resolvers.fromHref(),
          price: () =>
            evergage.util.getFloatValue(
              evergage
                .cashDom("[data-evergage-product-price]")
                .attr("data-evergage-product-price"),
            ),
          imageUrl: evergage
            .cashDom("[data-evergage-image-url]")
            .first()
            .attr("data-evergage-image-url"),
          CNC: evergage.cashDom('[data-evergage-cnc="true"]').length > 0,
          relatedCatalogObjects: {
            Color: () =>
              evergage.resolvers.fromSelectorAttributeMultiple(
                "[data-evergage-color]",
                "data-evergage-color",
                (colors: any) =>
                  colors.map((color: any) => color.trim().toUpperCase()),
              ),
            Size: evergage
              .cashDom("[data-evergage-sizes]")
              .attr("data-evergage-sizes")
              ?.split(","),
            Category: getCategories(evergage, 1),
          },
        },
      },
      listeners: [
        evergage.listener("click", "#share-button", () => {
          evergage.sendEvent({
            name: EvergageActions.SHARED_CATALOG_OBJECT,
            itemAction: evergage.ItemAction.Share,
            catalog: {
              Product: {
                _id: evergage
                  .cashDom("[data-evergage-product-id]")
                  .attr("data-evergage-product-id"),
                name: evergage
                  .cashDom("[data-evergage-product-name]")
                  .attr("data-evergage-product-name"),
                category: getCategories(evergage),
                color: evergage
                  .cashDom("[data-evergage-product-color]")
                  .attr("data-evergage-product-color"),
              },
            },
          });
        }),
        evergage.listener(
          "click",
          '[data-evergage="shopping-list-button"]',
          (e: any) => {
            const dataAction = evergage
              .cashDom(e.target)
              .closest("[data-evergage='shopping-list-button']")
              .attr("data-evergage-action");

            if (!dataAction) {
              return;
            }

            const isRemoveFromShoppingList = dataAction === "true";

            evergage.sendEvent({
              action: isRemoveFromShoppingList
                ? EvergageActions.REMOVE_FROM_SHOPPING_LIST
                : EvergageActions.ADD_TO_SHOPPING_LIST,
              itemAction: evergage.ItemAction.Favorite,
              catalog: {
                Product: {
                  _id: evergage
                    .cashDom("[data-evergage-product-id]")
                    .attr("data-evergage-product-id"),
                  name: evergage
                    .cashDom("[data-evergage-product-name]")
                    .attr("data-evergage-product-name"),
                  category: getCategories(evergage),
                  color: evergage
                    .cashDom("[data-evergage-product-color]")
                    .attr("data-evergage-product-color"),
                },
              },
            });
          },
        ),
        evergage.listener("click", '[data-evergage="search-location"]', () => {
          evergage.sendEvent({
            action: EvergageActions.SEARCH_LOCATION,
          });
        }),
        evergage.listener("click", '[data-evergage="add-to-bag"]', () => {
          const cartData = evergage.util.buildLineItemFromPageState();
          cartData.quantity = 1;
          evergage.sendEvent({
            itemAction: evergage.ItemAction.AddToCart,
            cart: {
              singleLine: {
                Product: cartData,
              },
            },
          });
        }),
      ],
    },
    {
      name: "CLP",
      action: EvergageActions.CATEGORY_VIEW,
      isMatch: () => {
        const arr = location.pathname.split("/");
        return (
          arr.includes("c") &&
          arr.length <= 4 &&
          evergage.cashDom(pageSelector(EvergagePage.CLP)).length > 0
        );
      },
      WebConsent: true,
      catalog: {
        Category: {
          _id: () =>
            evergage.resolvers.fromHref((url: any) =>
              url.split("?")[0].split("/").pop().toUpperCase(),
            ),
          categoryName: () =>
            evergage.resolvers.fromHref((url: any) =>
              url.split("?")[0].split("/").pop().toUpperCase(),
            )(),
        },
      },
      contentZones: [
        {
          name: "Category_Hero_1",
          selector: zoneSelector(EvergageContentZones.HERO_BANNER),
        },
        {
          name: "Category_Hero_2",
          selector: zoneSelector(EvergageContentZones.COLLECTION_BANNER),
        },
        {
          name: "Category_Hero_3",
          selector: zoneSelector(EvergageContentZones.HERO_PRODUCT_CAROUSEL),
        },
        {
          name: "Category_Footer",
          selector: zoneSelector(EvergageContentZones.GENERIC_BANNER),
        },
        {
          name: "Category_Editorial_Inspirations",
          selector: zoneSelector(EvergageContentZones.EDITORIAL),
        },
      ],
    },
    {
      name: "PLP",
      action: EvergageActions.PLP_VIEW,
      isMatch: () => {
        const arr = location.pathname.split("/");
        return (
          arr.includes("c") &&
          arr.length >= 4 &&
          evergage.cashDom(pageSelector(EvergagePage.PLP)).length > 0
        );
      },
      WebConsent: true,
      catalog: {
        Category: {
          _id: () =>
            (getCategoriesFromJsonLd(evergage) as Array<string>).reduce(
              (acc, category) => `${acc ? `${acc}|` : ""}${category}`,
              "",
            ),
          primaryCategory: () => getCategoriesFromJsonLd(evergage)[0],
          subCategory: () =>
            (getCategoriesFromJsonLd(evergage) as Array<string>).reduce(
              (acc, category, i) => {
                if (i === 0) {
                  return "";
                }
                return `${acc ? `${acc}|` : ""}${category}`;
              },
              "",
            ),
        },
      },
    },
    {
      name: "order_confirmation",
      isMatch: () => location.pathname.split("/").includes("order-confirmation"),
    },
    {
      name: "article",
      action: EvergageActions.VIEW_ARTICLE,
      isMatch: () => {
        const arr = location.pathname.split("/");
        return (
          arr.includes("a") &&
          evergage.cashDom(pageSelector(EvergagePage.ARTICLE)).length > 0
        );
      },
      WebConsent: true,
      catalog: {
        Article: {
          _id: () =>
            evergage.resolvers.fromCanonical((url: any) =>
              url.split("/").pop().toUpperCase(),
            ),
          name: () =>
            evergage
              .cashDom("[data-evergage-article-name]")
              .attr("data-evergage-article-name"),
          url: evergage.resolvers.fromCanonical(),
          imageUrl: evergage
            .cashDom(`${zoneSelector(EvergageContentZones.HERO_BLOCK)} img`)
            .attr("src"),
          relatedCatalogObjects: {
            Category: [
              evergage
                .cashDom(`${zoneSelector(EvergageContentZones.HERO_BLOCK)}`)
                .attr("data-evergage-theme")
                ?.toUpperCase(),
            ],
          },
        },
      },
      contentZones: [
        {
          name: "Related_Articles",
          selector: zoneSelector(EvergageContentZones.ARTICLES_CAROUSEL),
        },
      ],
    },
    {
      name: "shopping_list",
      action: EvergageActions.VIEW_SHOPPING_LIST,
      isMatch: () =>
        evergage.cashDom(pageSelector(EvergagePage.SHOPPING_LIST)).length > 0,
    },
  ],
});

/* eslint-enable @typescript-eslint/no-explicit-any */
