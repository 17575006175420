"use client";
import React from "react";

import { styled } from "@mui/material/styles";
import { capitalize } from "@mui/material/utils";
import clsx from "clsx";

import { getTestAutomationProps } from "~/helpers/test-automation-props";

const BASE_SVG_ICON_PREFIX = "BaseSVGIcon";

const BASE_SVG_ICON_CLASSES = {
  root: `${BASE_SVG_ICON_PREFIX}-root`,
  colorPrimary: `${BASE_SVG_ICON_PREFIX}-colorPrimary`,
  colorSecondary: `${BASE_SVG_ICON_PREFIX}-colorSecondary`,
  colorAction: `${BASE_SVG_ICON_PREFIX}-colorAction`,
  colorError: `${BASE_SVG_ICON_PREFIX}-colorError`,
  colorDisabled: `${BASE_SVG_ICON_PREFIX}-colorDisabled`,
  colorSuccess: `${BASE_SVG_ICON_PREFIX}-colorSuccess`,
  colorWarning: `${BASE_SVG_ICON_PREFIX}-colorWarning`,
  fontSizeInherit: `${BASE_SVG_ICON_PREFIX}-fontSizeInherit`,
  fontSizeSmall: `${BASE_SVG_ICON_PREFIX}-fontSizeSmall`,
  fontSizeLarge: `${BASE_SVG_ICON_PREFIX}-fontSizeLarge`,
};

const SvgStyled = styled("svg")(({ theme }) => {
  return {
    userSelect: "none",
    display: "inline-block",
    flexShrink: 0,
    transition: theme.transitions.create("fill", {
      duration: theme.transitions.duration.shorter,
    }),
    /* Styles applied to the root element if `color="primary"`. */
    [`&.${BASE_SVG_ICON_CLASSES.colorPrimary}`]: {
      color: theme.palette.primary.main,
    },
    /* Styles applied to the root element if `color="secondary"`. */
    [`&.${BASE_SVG_ICON_CLASSES.colorSecondary}`]: {
      color: theme.palette.secondary.main,
    },
    /* Styles applied to the root element if `color="action"`. */
    [`&.${BASE_SVG_ICON_CLASSES.colorAction}`]: {
      color: theme.palette.action.active,
    },
    /* Styles applied to the root element if `color="error"`. */
    [`&.${BASE_SVG_ICON_CLASSES.colorError}`]: {
      color: theme.palette.error.main,
    },
    /* Styles applied to the root element if `color="disabled"`. */
    [`&.${BASE_SVG_ICON_CLASSES.colorDisabled}`]: {
      color: theme.palette.action.disabled,
    },
    /* Styles applied to the root element if 'color="success". */
    [`&.${BASE_SVG_ICON_CLASSES.colorSuccess}`]: {
      color: theme.palette.success.main,
    },
    /* Styles applied to the root element if 'color="warning". */
    [`&.${BASE_SVG_ICON_CLASSES.colorWarning}`]: {
      color: theme.palette.warning.main,
    },
    /* Styles applied to the root element if `fontSize="inherit"`. */
    [`&.${BASE_SVG_ICON_CLASSES.fontSizeInherit}`]: {
      fontSize: "inherit",
    },
    /* Styles applied to the root element if `fontSize="small"`. */
    [`&.${BASE_SVG_ICON_CLASSES.fontSizeSmall}`]: {
      fontSize: theme.typography.pxToRem(20),
    },
    /* Styles applied to the root element if `fontSize="large"`. */
    [`&.${BASE_SVG_ICON_CLASSES.fontSizeLarge}`]: {
      fontSize: theme.typography.pxToRem(35),
    },
  };
});

export interface BaseSVGIconProps extends React.SVGProps<SVGSVGElement> {
  color?:
    | "action"
    | "disabled"
    | "error"
    | "warning"
    | "inherit"
    | "primary"
    | "secondary"
    | "success";
  htmlColor?: string;
  fontSize?: "default" | "inherit" | "large" | "small";
  viewBox?: string;
  width?: string;
  height?: string;
  fill?: string;
  stroke?: string;
  title?: string;
}

export const BaseSVGIcon: React.FC<BaseSVGIconProps> = ({
  children,
  color = "inherit",
  htmlColor,
  fontSize = "default",
  viewBox = "0 0 24 24",
  width,
  height,
  fill,
  stroke,
  ...passedProps
}) => {
  return (
    <SvgStyled
      {...passedProps}
      className={clsx(
        {
          [BASE_SVG_ICON_CLASSES[
            `color${capitalize(color)}` as keyof typeof BASE_SVG_ICON_CLASSES
          ]]: color !== "inherit",
          [BASE_SVG_ICON_CLASSES[
            `fontSize${capitalize(fontSize)}` as keyof typeof BASE_SVG_ICON_CLASSES
          ]]: fontSize !== "default",
        },
        passedProps.className,
      )}
      focusable="false"
      viewBox={viewBox}
      aria-hidden={passedProps.title ? undefined : true}
      role={undefined}
      width={width}
      height={height}
      fill={fill}
      color={htmlColor}
      stroke={stroke}
      {...getTestAutomationProps("icon")}
    >
      {children}
    </SvgStyled>
  );
};
