import { GlobalsProps } from "./types";

const globalInitializationCache = new Map<keyof Window, Promise<string>>();

export const awaitGlobalsInitialization = async ({
  windowVar,
  maxAttempts = 100,
  timeout = 100,
}: GlobalsProps): Promise<string | undefined> => {
  if (globalInitializationCache.has(windowVar)) {
    return globalInitializationCache.get(windowVar);
  }

  const initializationPromise = new Promise<string>((resolve, reject) => {
    let attempt = 0;

    const intervalID = setInterval(() => {
      // eslint-disable-next-line no-restricted-globals
      const isGlobalVarDefined = typeof window[windowVar] !== "undefined";

      if (attempt > maxAttempts) {
        clearInterval(intervalID);
        globalInitializationCache.delete(windowVar);
        reject(
          new Error(
            `Failed to initialize '${String(windowVar)}' in ${maxAttempts} attempts`,
          ),
        );
      }

      if (isGlobalVarDefined) {
        clearInterval(intervalID);
        resolve(`${String(windowVar)} initialized`);
      }
      attempt++;
    }, timeout);
  });

  globalInitializationCache.set(windowVar, initializationPromise);
  return initializationPromise;
};
