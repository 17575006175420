// Primary
export const COLOR_PRIMARY = "#00AFDB";
export const COLOR_PRIMARY_LIGHT = "#E5F5FB";
export const COLOR_PRIMARY_BLACK = "#000000";
export const COLOR_PRIMARY_WHITE = "#FFFFFF";

// Additional
export const COLOR_ADDITIONAL_WARM_GRAY_3U = "#F5F3F2";
export const COLOR_ADDITIONAL_ALABASTER = "#F4F1E6";
export const COLOR_ADDITIONAL_VENETIAN_RED = "#D0050C";
export const COLOR_ADDITIONAL_MANGO_TANGO = "#E56800";
export const COLOR_ADDITIONAL_SUNGLOW = "#FFCE2E";
export const COLOR_ADDITIONAL_JAPANESE_LAUREL = "#27A770";
export const COLOR_ADDITIONAL_DODGER_BLUE = "#3377FF";

// Pastel
export const COLOR_PASTEL_OYSTER_BAY = "#DDF0F3";
export const COLOR_PASTEL_AMOUR = "#FCEBEC";
export const COLOR_PASTEL_ISLAND_SPICE = "#F6ECDD";
export const COLOR_PASTEL_WILLOW_BROOK = "#E4EFD3";

// Greyscale
export const COLOR_GREYSCALE_BLACK_75 = "rgba(0, 0, 0, 0.75)";
export const COLOR_GREYSCALE_BLACK_54 = "rgba(0, 0, 0, 0.54)";
export const COLOR_GREYSCALE_BLACK_42 = "rgba(0, 0, 0, 0.42)";
export const COLOR_GREYSCALE_BLACK_25 = "rgba(0, 0, 0, 0.25)";
export const COLOR_GREYSCALE_BLACK_15 = "rgba(0, 0, 0, 0.15)";
export const COLOR_GREYSCALE_BLACK_8 = "rgba(0, 0, 0, 0.08)";
export const COLOR_GREYSCALE_BLACK_5 = "rgba(0, 0, 0, 0.05)";
export const COLOR_GREYSCALE_BLACK_4 = "rgba(0, 0, 0, 0.04)";

// Greyscale Non Transparent Analog
export const COLOR_GREYSCALE_NTA_BLACK_90 = "#1B1D20";
export const COLOR_GREYSCALE_NTA_BLACK_80 = "#31343A";
export const COLOR_GREYSCALE_NTA_BLACK_75 = "#404040";
export const COLOR_GREYSCALE_NTA_BLACK_60 = "#3F444A";
export const COLOR_GREYSCALE_NTA_BLACK_50 = "#808080";
export const COLOR_GREYSCALE_NTA_BLACK_40 = "#73747B";
export const COLOR_GREYSCALE_NTA_BLACK_25 = "#BFBFBF";
export const COLOR_GREYSCALE_NTA_BLACK_20 = "#F5F5F9";
export const COLOR_GREYSCALE_NTA_BLACK_15 = "#D9D9D9";
export const COLOR_GREYSCALE_NTA_BLACK_8 = "#EAEAEA";
export const COLOR_GREYSCALE_NTA_BLACK_5 = "#F2F2F2";

// Other
export const COLOR_BLACK = "#000000";
export const COLOR_WHITE = "#ffffff";
export const COLOR_FOCUSED = "#3377FF";
export const COLOR_BUTTON_HOVER = "#F5F5F6";
export const COLOR_BUTTON_ACTIVE = "#E1E2E3";

// Transparent
export const COLOR_TRANSPARENT = "rgba(0, 0, 0, 0)";
// Misc
// CTALink
export const COLOR_BLUE_BRAND_50 = "#7FD6EC";
export const COLOR_BLUE_BRAND_60 = "#00B7E1";

export const COLOR_BLACK_BRAND_75 = "rgba(56, 62, 66, 0.75)";

export const COLOR_NEON_GREEN = "rgba(201,255,3)";

// Statuses
export const COLOR_SUCCESS = "#2C7D3D";
export const COLOR_SUCCESS_BACKGROUND = "#E4EFD3";
export const COLOR_WARNING = "#E56800";
export const COLOR_WARNING_BACKGROUND = "#F6ECDD";
export const COLOR_ERROR = "#D0050C";
export const COLOR_ERROR_DARK = "#BC0000";
export const COLOR_ERROR_BACKGROUND = "#FCEBEC";
export const COLOR_DISABLED = COLOR_BLACK;
export const COLOR_DISABLED_BACKGROUND = COLOR_ADDITIONAL_WARM_GRAY_3U;
export const COLOR_NOT_RANGED = COLOR_GREYSCALE_NTA_BLACK_75;
export const COLOR_DISABLED_ICON = COLOR_GREYSCALE_NTA_BLACK_50;

// Warning message
export const COLOR_WARNING_RED = " #D0050C";
