import { gql } from "@apollo/client";

export const GET_BILLING_ADDRESSES_FIELDS = gql`
  fragment billingAddressesFields on BillingAddress {
    id
    firstName
    lastName
    title
    city
    country
    line1
    line2
    postCode
  }
`;

export const GET_PAYMENTMETHODS_FIELDS = gql`
  fragment paymentMethodsFields on PaymentMethod {
    id
    type
    maskedPan
    cardSchema
    expirationDate
    country
    psp
    token
    billingAddress {
      id
      firstName
      lastName
      title
      city
      country
      line1
      line2
      postCode
    }
  }
`;

export const ACCOUNT_FIELDS = gql`
  fragment accountFields on Account {
    firstName
    lastName
    email
    dateOfBirth {
      day
      month
      year
    }
    personContactId
    selectedStore
    preferredStore
    personHasOpedOutOfEmail
    marketingCommunicationOptInPc
    uid
    country
    postalCode
    preferredLanguage
    verifiedOptIn
    verifiedOptInDate
    verifiedOptInTrigger
    billingAddresses {
      ...billingAddressesFields
    }
    paymentMethods {
      ...paymentMethodsFields
    }
  }
  ${GET_BILLING_ADDRESSES_FIELDS}
  ${GET_PAYMENTMETHODS_FIELDS}
`;

export const GET_ACCOUNT = gql`
  query Account($locale: String!) {
    account(locale: $locale) {
      ...accountFields
    }
  }
  ${ACCOUNT_FIELDS}
`;

export const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($account: UpdateAccountInput!, $locale: String) {
    updateAccount(account: $account, locale: $locale) {
      firstName
      lastName
      email
      personContactId
      selectedStore
      preferredStore
      personHasOpedOutOfEmail
      marketingCommunicationOptInPc
      country
      postalCode
      preferredLanguage
      verifiedOptIn
      verifiedOptInDate
      verifiedOptInTrigger
      dateOfBirth {
        day
        month
        year
      }
      billingAddresses {
        ...billingAddressesFields
      }
      paymentMethods {
        ...paymentMethodsFields
      }
      errors
    }
  }
  ${GET_BILLING_ADDRESSES_FIELDS}
  ${GET_PAYMENTMETHODS_FIELDS}
`;

export const UPDATE_ACCOUNT_LAST_INTERACTION_DATE = gql`
  mutation UpdateAccountForLastInteractionDate($locale: String!) {
    updateAccountForLastInteractionDate(locale: $locale)
  }
`;
