import Cookies from "js-cookie";

import { Account } from "~/bff/types/Account";
import { SELECTED_STORE, PREFERRED_STORE } from "~/constants/user-cookies";

export const getSelectedOrPreferredStoreId = (
  account?: Account | null,
): string | undefined =>
  Cookies.get(SELECTED_STORE) ??
  account?.selectedStore ??
  Cookies.get(PREFERRED_STORE);
