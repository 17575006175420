import React from "react";

import { MyAccountNavigationProps } from "~/bff/components/MyAccountNavigation";
import { Account } from "~/bff/types/Account";
import { STATES } from "~/components/account-page/types";

export type AuthContextData = {
  account?: Account;
  updateAccount?: (account: Account | null) => void;
  resetAccount?: () => void;
  selectedState?: STATES;
  setSelectedState: (states: STATES) => void;
  setAccountNavigation?: (navigationProps: MyAccountNavigationProps) => void;
  accountNavigation?: MyAccountNavigationProps;
  isMemberOfTrialStoreGroup?: boolean;
} | null;

export const AuthContext = React.createContext<AuthContextData>(null);
