import { LOCALES } from "~/constants/i18n";

import { getCountry } from "./get-country";
import { CountryCode } from "./types";

export const getCountryCode = (locale: LOCALES): CountryCode | null => {
  const country = getCountry(locale);

  return country?.code2 ?? null;
};
