export enum EvergageActions {
  HOME_PAGE_VIEW = "Home page view",
  PRODUCT_DETAILS = "Product Detail",
  NEWSLETTER_SUBSCRIBED = "Newsletter Subscribed",
  CATEGORY_VIEW = "Category View",
  PLP_VIEW = "PLP View",
  SHARED_CATALOG_OBJECT = "Share Catalog Object",
  REGISTRATION_SUCCESSFUL = "Account Registration Successful",
  ADD_TO_SHOPPING_LIST = "Add to Shopping List",
  REMOVE_FROM_SHOPPING_LIST = "Remove from shopping list",
  SEARCH_LOCATION = "Search Location",
  VIEW_ARTICLE = "Article View",
  VIEW_SHOPPING_LIST = "Shopping List View",
  NEWSLETTER_SUBSCRIBE = "Profile subscription",
  NEWSLETTER_UNSUBSCRIBE = "Profile unsubscribe",
}

export enum EvergageItemActions {
  ADD_TO_CART = "Add To Cart",
  VIEW_ITEM_OUT_OF_STOCK = "View Item Out Of Stock",
  VIEW_ITEM_DETAIL = "View Item Detail",
  QUICK_VIEW_ITEM = "Quick View Item",
  STOP_QUICK_VIEW_ITEM = "Stop Quick View Item",
  VIEW_CATEGORY = "View Category",
  VIEW_TAG = "View Tag",
  VIEW_CART = "View Cart",
  PURCHASE = "Purchase",
  SHARE = "Share",
  REVIEW = "Review",
  COMMENT = "Comment",
  FAVORITE = "Favorite",
  SEARCH = "Search",
  SEARCH_VIEW_RESULTS = "Search View Results",
  SEARCH_CLICK = "Search Click",
  CLICK_THROUGH = "Click Through",
  UPDATE_LINE_ITEM = "Update Line Item",
  REMOVE_FROM_CART = "Remove From Cart",
  VIEW_BANDIT_ITEMS = "View Bandit Items",
  CHANGE_CART_URL = "Change cart URL",
}
