export enum EvergagePage {
  PLP = "plp",
  CLP = "clp",
  ARTICLE = "article",
  SHOPPING_LIST = "shopping-list",
}

export enum EvergageContentZones {
  HOME_HERO_BANNER = "HOME_HERO_BANNER",
  HERO_BANNER = "HERO_BANNER",
  HERO_BLOCK = "HERO_BLOCK",
  COLLECTION_BANNER = "COLLECTION_BANNER",
  HERO_PRODUCT_CAROUSEL = "HERO_PRODUCT_CAROUSEL",
  GENERIC_BANNER = "GENERIC_BANNER",
  EDITORIAL = "EDITORIAL",
  CROSS_SELL = "CROSS_SELL",
  ARTICLES_CAROUSEL = "ARTICLES_CAROUSEL",
}
