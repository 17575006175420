import { useCallback } from "react";

import { useIsAuthenticated } from "@azure/msal-react";

import { LOCALES } from "~/constants/i18n";
import { useAzureConfigurator } from "~/services/azure-configurator/use-azure-configurator";
import { Logger } from "~/utils/logger";

import { useAcquireTokenSilent } from "../use-acquire-token-silent/hook";
import { useLoginLogout } from "../use-login-logout";

export interface UseTokenReturnType {
  extractToken: () => Promise<string | null | undefined>;
}

export const useToken = (locale: LOCALES): UseTokenReturnType => {
  const { startLogout } = useLoginLogout(locale);

  const config = useAzureConfigurator(locale);

  const isMyAccountEnabled = config?.featureFlags?.myAccount?.enabled;

  const isAuthenticated = useIsAuthenticated();

  const { acquireTokenSilent } = useAcquireTokenSilent(locale);

  const extractToken = useCallback(async (): Promise<string | undefined | null> => {
    if (isAuthenticated && !isMyAccountEnabled) {
      startLogout(false);

      return;
    }
    if (!isAuthenticated) {
      return Promise.reject(new Error("User is not authenticated"));
    }

    try {
      const token = await acquireTokenSilent();

      return token;
    } catch (error) {
      Logger.getLogger().error(String(error));

      startLogout();
    }
  }, [isAuthenticated, isMyAccountEnabled, startLogout, acquireTokenSilent]);

  return {
    extractToken,
  };
};
