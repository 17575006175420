import { ServerParseError } from "@apollo/client";
import { ErrorResponse } from "@apollo/client/link/persisted-queries";

import { DEFAULT_LOCALE } from "~/constants/i18n";
import { EventNames as NREventNames } from "~/constants/new-relic";
import { Params, Routes } from "~/constants/request";
import { Logger } from "~/utils/logger";

export function handleNetworkError(context: ErrorResponse) {
  const { networkError, operation } = context;

  if (networkError) {
    const errorDetail = {
      operation: operation.operationName,
      locale: operation.variables?.locale || DEFAULT_LOCALE,
      error: networkError,
      statusCode: (networkError as ServerParseError).statusCode,
    };

    Logger.getLogger("GraphQL.networkError").error(JSON.stringify(errorDetail));

    global?.window?.newrelic?.addPageAction(NREventNames.networkError, errorDetail);
  }

  if (networkError && (networkError as ServerParseError).statusCode === 401) {
    const locale = operation.variables?.locale || DEFAULT_LOCALE;
    // eslint-disable-next-line no-restricted-globals
    window.location.href = `/${locale}${Routes.LOGOUT}?${Params.SESSION_EXPIRED_POPUP}=true`;
  }
}
