export const SHOPPING_BAG_MERGING_POPUP = "shopping-bag-merging-popup";
export const PRODUCT_CARD_INDEX = "product_card_index";
export const PREVIOUS_PATH = "previousPath";
export const PREVIOUS_CURRENT_LOCALES = "previousCurrentLocales";
export const CHECKOUT_SIGN_IN = "checkoutSignIn";

export enum SHOPPING_BAG_MERGING_POPUP_TYPE {
  ANONYMOUS = 'anonymous',
  ABANDONED = 'abandoned',
}
