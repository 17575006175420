import { LOCALES } from "~/constants/i18n";

import countries from "../countries.json";

import { Country } from "./types";

export const getCountry = (locale: LOCALES): Country | null => {
  const countryCode = locale.split("-").pop();

  if (countryCode === undefined) {
    return null;
  }

  const country = countries.find(
    (country) => country.code2.toLowerCase() === countryCode.toLowerCase(),
  );

  if (country === undefined) {
    return null;
  }

  const countryAs = country as Country;

  return countryAs;
};
