import { gql } from "@apollo/client";

export const GET_SHOPPING_BAG_PRODUCT_FIELDS = gql`
  fragment shoppingBagProductFields on ShoppingListProduct {
    id
    addedAt
    quantity
    productSlug
    productCode
    name
    sku
    category
    subCategory
    subSubCategory
    subSubSubCategory
    styleCode
    displayColor
    startDate
    endDate
    displaySize
    sizeId
    images {
      url
      label
    }
    price
    variants {
      sku
      displaySize
      sizeId
      salesChannel
    }
    pricePerUnit
    measureOfPrice
    uomOfPrice
  }
`;

export const GET_USER_SHOPPING_BAG = gql`
  query ShoppingBag($currencyCode: String!, $locale: String!) {
    shoppingBag(locale: $locale, currencyCode: $currencyCode) {
      id
      anonymousId
      isMergedAnonymousList
      products {
        ...shoppingBagProductFields
      }
      totalPrice
      validateCart(locale: $locale) {
        statusCode
        errors {
          reason
          message
          expected
          actual
          lineItemId
        }
      }
      storeLimits(locale: $locale) {
        maxLineItemQuantity
      }
    }
  }
  ${GET_SHOPPING_BAG_PRODUCT_FIELDS}
`;

export const GET_USER_SHOPPING_BAG_WITHOUT_VALIDATION = gql`
  query ShoppingBagWithoutValidate($currencyCode: String!, $locale: String!) {
    shoppingBag(locale: $locale, currencyCode: $currencyCode) {
      id
      anonymousId
      isMergedAnonymousList
      products {
        ...shoppingBagProductFields
      }
      totalPrice
      storeLimits(locale: $locale) {
        maxLineItemQuantity
      }
    }
  }
  ${GET_SHOPPING_BAG_PRODUCT_FIELDS}
`;

export const ADD_PRODUCT_TO_SHOPPING_BAG = gql`
  mutation AddShoppingBagItem(
    $locale: String!
    $currencyCode: String!
    $sku: String!
    $shoppingBagId: String
    $quantity: Int!
    $collectionStoreId: String!
  ) {
    addShoppingBagItem(
      locale: $locale
      currencyCode: $currencyCode
      sku: $sku
      shoppingBagId: $shoppingBagId
      quantity: $quantity
      collectionStoreId: $collectionStoreId
    ) {
      id
      anonymousId
      products {
        ...shoppingBagProductFields
      }
      totalPrice
      storeLimits(locale: $locale) {
        maxLineItemQuantity
      }
      validateCart(locale: $locale) {
        statusCode
        errors {
          reason
          message
          expected
          actual
          lineItemId
        }
      }
    }
  }
  ${GET_SHOPPING_BAG_PRODUCT_FIELDS}
`;

export const ADD_ABANDONED_PRODUCTS_TO_SHOPPING_BAG = gql`
  mutation AddShoppingBagAbandonedItems(
    $locale: String!
    $currencyCode: String!
    $items: [AbandonedItem!]!
    $shoppingBagId: String
    $collectionStoreId: String!
  ) {
    addShoppingBagAbandonedItems(
      locale: $locale
      currencyCode: $currencyCode
      items: $items
      shoppingBagId: $shoppingBagId
      collectionStoreId: $collectionStoreId
    ) {
      id
      anonymousId
      products {
        ...shoppingBagProductFields
      }
      totalPrice
      storeLimits(locale: $locale) {
        maxLineItemQuantity
      }
      isMergedAbandonedList
    }
  }
  ${GET_SHOPPING_BAG_PRODUCT_FIELDS}
`;

export const REPLACE_SHOPPING_BAG_ITEM = gql`
  mutation ReplaceShoppingBagItem(
    $shoppingBagId: String!
    $removableItemId: String!
    $locale: String!
    $currencyCode: String!
    $sku: String!
    $quantity: Int!
    $addedAt: String
  ) {
    replaceShoppingBagItem(
      shoppingBagId: $shoppingBagId
      removableItemId: $removableItemId
      locale: $locale
      currencyCode: $currencyCode
      sku: $sku
      quantity: $quantity
      addedAt: $addedAt
    ) {
      id
      anonymousId
      products {
        ...shoppingBagProductFields
      }
      totalPrice
      validateCart(locale: $locale) {
        statusCode
        errors {
          reason
          message
          expected
          actual
          lineItemId
        }
      }
      storeLimits(locale: $locale) {
        maxLineItemQuantity
      }
    }
  }
  ${GET_SHOPPING_BAG_PRODUCT_FIELDS}
`;

export const UPDATE_SHOPPING_BAG_LOCATION_ID = gql`
  mutation UpdateShoppingBagLocationId(
    $locationId: String!
    $currencyCode: String!
    $locale: String!
  ) {
    updateShoppingBagLocationId(
      locationId: $locationId
      locale: $locale
      currencyCode: $currencyCode
    ) {
      id
      version
      anonymousId
      products {
        ...shoppingBagProductFields
      }
      totalPrice
      validateCart(locale: $locale) {
        statusCode
        errors {
          reason
          message
          expected
          actual
          lineItemId
        }
      }
      storeLimits(locale: $locale) {
        maxLineItemQuantity
      }
    }
  }
  ${GET_SHOPPING_BAG_PRODUCT_FIELDS}
`;

export const MOVE_ITEM_FROM_SHOPPING_BAG_TO_SHOPPING_LIST = gql`
  mutation MoveItemFromShoppingBagToShoppingList(
    $shoppingBagId: String!
    $shoppingListId: String
    $removableItemId: String!
    $sku: String!
    $locale: String!
    $currencyCode: String!
    $addedAt: String
  ) {
    moveItemFromShoppingBagToShoppingList(
      shoppingBagId: $shoppingBagId
      shoppingListId: $shoppingListId
      removableItemId: $removableItemId
      locale: $locale
      currencyCode: $currencyCode
      sku: $sku
      addedAt: $addedAt
    ) {
      id
      anonymousId
      products {
        ...shoppingBagProductFields
      }
      totalPrice
      validateCart(locale: $locale) {
        statusCode
        errors {
          reason
          message
          expected
          actual
          lineItemId
        }
      }
      storeLimits(locale: $locale) {
        maxLineItemQuantity
      }
    }
  }
  ${GET_SHOPPING_BAG_PRODUCT_FIELDS}
`;

export const REMOVE_SHOPPING_BAG_ITEM = gql`
  mutation RemoveShoppingBagItem(
    $shoppingBagId: String!
    $removableItemId: String!
    $locale: String!
    $currencyCode: String!
  ) {
    removeShoppingBagItem(
      shoppingBagId: $shoppingBagId
      removableItemId: $removableItemId
      locale: $locale
      currencyCode: $currencyCode
    ) {
      id
      anonymousId
      products {
        ...shoppingBagProductFields
      }
      totalPrice
      validateCart(locale: $locale) {
        statusCode
        errors {
          reason
          message
          expected
          actual
          lineItemId
        }
      }
      storeLimits(locale: $locale) {
        maxLineItemQuantity
      }
    }
  }
  ${GET_SHOPPING_BAG_PRODUCT_FIELDS}
`;
