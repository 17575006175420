import { useMemo } from "react";

import { QueryTuple, useLazyQuery } from "@apollo/client";

import { AccountResponse, AccountVariables } from "~/bff/transport/Account";
import { Account } from "~/bff/types/Account";
import { LOCALES } from "~/constants/i18n";
import { getApolloClient } from "~/graphql/client";
import { GET_ACCOUNT } from "~/graphql/queries/accountQueries";
import { Logger } from "~/utils/logger";

export const useLoadAccountLazyQuery = (
  locale: LOCALES,
  completeHandler?: (account: Account | null) => void,
  errorHandler?: () => void,
): QueryTuple<AccountResponse, AccountVariables> => {
  const client = useMemo(() => getApolloClient({ locale }), [locale]);

  return useLazyQuery<AccountResponse, AccountVariables>(GET_ACCOUNT, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    client,
    onCompleted: ({ account }) => {
      completeHandler?.(account);
    },
    onError: (error) => {
      Logger.getLogger().error(error);
      errorHandler?.();
    },
  });
};
