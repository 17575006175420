import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  HttpLink,
} from "@apollo/client";
import { DefaultOptions } from "@apollo/client/core/ApolloClient";
import { ErrorLink, onError } from "@apollo/client/link/error";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import fetch from "cross-fetch";
import { sha256 } from "crypto-hash";

import { LOCALES } from "~/constants/i18n";
import { AzureConfigurator } from "~/services/azure-configurator/azure-configurator";
import { getOverridesForLocalDevelopment } from "~/services/azure-configurator/helpers";

import { handleNetworkError } from "../helpers/handle-network-error";

interface WithLocale {
  locale?: LOCALES;
}

export const getApolloClient = (
  options?: DefaultOptions & WithLocale,
): ApolloClient<NormalizedCacheObject> =>
  new ApolloClient({
    uri: IS_TEST
      ? AzureConfigurator.getConfig(options?.locale)?.apiBaseUrl
      : undefined,
    link: !IS_TEST
      ? createPersistedQueryLink({ sha256, useGETForHashedQueries: true })
          .concat(onError(handleNetworkError as unknown as ErrorLink.ErrorHandler))
          .concat(
            new HttpLink({
              uri: AzureConfigurator.getConfig(options?.locale)?.apiBaseUrl,
              credentials: "include",
              fetch,
            }),
          )
      : new HttpLink({
          uri:
            getOverridesForLocalDevelopment().apiBaseUrl ?? "http://localhost:3001/",
          fetch,
        }),
    ssrMode: IS_SERVER,
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
        ...(options?.watchQuery ?? {}),
      },
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
        ...(options?.query ?? {}),
      },
      mutate: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
        ...(options?.mutate ?? {}),
      },
    },
  });
