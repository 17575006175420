/* eslint @typescript-eslint/explicit-module-boundary-types: 0,@typescript-eslint/no-explicit-any: 0 */
// TODO: must be covered by types later, because it is a third-party library and requires manual typing

import { EventNames as NREventNames } from "~/constants/new-relic";
import { getParsedCookieCategories } from "~/helpers/one-trust/cookie-parser";
import { AzureConfigurator } from "~/services/azure-configurator/azure-configurator";
import { Logger } from "~/utils/logger/browser";

import { EvergageActions } from "./actions";
import { evergageSitemapConfig } from "./sitemap-config";

type EvergageType = any;

interface CallbackParams {
  [param: string]: any;
}

interface Callback {
  (param?: CallbackParams): void;
}

const logger = Logger.getLogger();

export const INIT_INTERVAL = 100;
export const INIT_MAX_ATTEMPTS = 100;

export class Evergage {
  private static evergage: EvergageType = undefined;
  private static attempts = 0;
  private static initFailed = false;
  private static queue: Callback[] = [];

  static reset() {
    this.evergage = undefined;
    this.attempts = 0;
    this.initFailed = false;
    this.queue = [];
  }

  static async init(locale: string, sitemapConfig?: any): Promise<void> {
    const cookieDomain = AzureConfigurator.getConfig(locale)?.evergage?.cookieDomain;

    if (!IS_SERVER) {
      if (this.isFailedInit()) {
        return;
      }

      logger.info("Init evergage");

      this.initInterval(() => {
        this.evergage
          .init(locale, { cookieDomain })
          .then(() => {
            this.evergage.initSitemap(
              sitemapConfig || evergageSitemapConfig(this.evergage),
            );
            this.executeQueue(this.evergage);
          })
          .catch((error: any) => {
            this.initFailed = true;
            logger.error("Fail to init evergage", error.message);
            (global?.window as any)?.newrelic?.addPageAction(
              NREventNames.evergageInit,
              {
                error: `Fail to init evergage: ${JSON.stringify(error)}`,
                stack: error?.stack,
              },
            );
          });
      });
    }
  }

  private static convertLocale(locale: string) {
    const [languageCode, countryCode] = locale.split("-");
    return `${languageCode}_${countryCode.toUpperCase()}`;
  }

  static sendEvent(locale: string, data: any): void {
    this.exec(locale, () => {
      this?.evergage?.sendEvent({
        ...data,
        source: {
          ...data.source,
          locale: this.convertLocale(locale),
        },
      });
    });
  }

  static async trackSubscription(
    locale: string,
    data: { email: string; preferredStore?: string },
  ): Promise<any> {
    const { email, preferredStore } = data;
    return this.sendEvent(locale, {
      action: EvergageActions.NEWSLETTER_SUBSCRIBED,
      user: {
        attributes: {
          emailAddress: email,
          MarketingOptIn: true,
          ...(preferredStore && { preferredStore }),
        },
      },
    });
  }

  static isInitialized(): boolean {
    return this.evergage !== undefined;
  }

  static isFailedInit(): boolean {
    this.initFailed && logger.error("Evergage failed to initialize");

    return this.initFailed;
  }

  private static addToQueue(cb: Callback): void {
    this.queue.push(cb);
  }

  private static executeQueue(evergage: EvergageType): void {
    this.queue.forEach((cb) => cb(evergage));
    this.queue = [];
  }

  private static initInterval(cb: () => void): void {
    this.evergage = (global?.window as any)?.Evergage;

    if (!this.isInitialized()) {
      if (this.attempts <= INIT_MAX_ATTEMPTS) {
        setTimeout(() => this.initInterval(cb), INIT_INTERVAL);
      } else {
        this.initFailed = true;
        logger.error("Evergage failed to initialize");

        const cookieCategoriesList = getParsedCookieCategories();
        (global?.window as any)?.newrelic?.addPageAction(
          NREventNames.evergageSnippetInit,
          {
            message: `Evergage snippet failed to initialize. Cookie categories: ${cookieCategoriesList}`,
          },
        );
      }
    } else {
      logger.info("Evergage is initialized");
      cb();
    }

    this.attempts++;
  }

  private static exec(locale: string, cb: () => void): void | string {
    if (!IS_SERVER) {
      if (this.isFailedInit()) {
        return;
      }

      this.addToQueue(cb);

      if (!this.isInitialized()) {
        this.init(locale);
      } else {
        this.executeQueue(this.evergage);
      }
    }
  }
}
