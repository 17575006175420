import * as msal from "@azure/msal-browser";

import { LOCALES } from "~/constants/i18n";

import { getClientId } from "./get-client-id";

export const performLoginRequest = (locale: LOCALES): msal.RedirectRequest => {
  const scopes: string[] = [];

  const clientId = getClientId(locale);

  if (typeof clientId === "string") {
    scopes.push(clientId);
  }

  return {
    scopes,
    prompt: "login",
  };
};
