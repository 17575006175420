import Cookies from "js-cookie";

import { ShoppingList } from "~/bff/types/ShoppingList";
import { ShoppingListProduct as ShoppingListProductAsSourceType } from "~/bff/types/ShoppingListProduct";
import { getCurrencyCodeByLocale } from "~/constants/i18n";
import { SELECTED_STORE } from "~/constants/user-cookies";
import { EvergageItemActions } from "~/lib/salesforce/evergage/actions";
import { AzureConfigurator } from "~/services/azure-configurator/azure-configurator";
import { Nullable } from "~/types/general.types";

export interface EventData {
  action: string;
  user: {
    attributes: {
      cartUrl: string;
    };
  };
}

function parseShoppingBag(shoppingBag: ShoppingList, locale: string): string {
  let url = `${
    AzureConfigurator.getConfig(locale)?.frontEndBaseUrl
  }/${locale}/shopping-bag?`;
  if (shoppingBag?.products?.length) {
    const productList = shoppingBag.products
      .map(
        (product: Nullable<ShoppingListProductAsSourceType>) =>
          `${product?.sku}:${product?.quantity}`,
      )
      .join(",");
    url += `abandonedCart=${productList}&`;
  }
  url += `currencyCode=${getCurrencyCodeByLocale(
    locale,
  )}&collectionStoreId=${Cookies.get(SELECTED_STORE)}`;
  return url;
}

export function getChangeCartUrlEventData(
  shoppingBag: ShoppingList,
  locale: string,
): EventData {
  return {
    action: EvergageItemActions.CHANGE_CART_URL,
    user: {
      attributes: {
        cartUrl: parseShoppingBag(shoppingBag, locale),
      },
    },
  };
}
