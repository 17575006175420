import { LOCALES } from "~/constants/i18n";

import { AzureConfigurator } from "../azure-configurator/azure-configurator";

import { B2cPolicies } from "./types";

export const getB2cPolicies = (locale: LOCALES): B2cPolicies => {
  const AZURE_AD_B2C_TENANT =
    AzureConfigurator.getConfig(locale)?.azure?.adB2C?.tenant;
  const AZURE_AD_B2C_SIGNUP_SIGNIN_FLOW =
    AzureConfigurator.getConfig(locale)?.azure?.adB2C?.signUpSignInFlow;
  const AZURE_AD_B2C_CHANGE_PASSWORD_FLOW =
    AzureConfigurator.getConfig(locale)?.azure?.adB2C?.changePasswordFlow;
  const AZURE_AD_B2C_RESET_PASSWORD_FLOW =
    AzureConfigurator.getConfig(locale)?.azure?.adB2C?.resetPasswordFlow;

  const AZURE_AD_B2C_SIGNUP_SIGNIN_DOMAIN =
    AzureConfigurator.getConfig(locale)?.azure?.adB2C?.customDomains?.signUpSignIn;

  const generateAuthority = (
    flow?: string,
    customDomain = `${AZURE_AD_B2C_TENANT}.b2clogin.com`,
  ) => `https://${customDomain}/${AZURE_AD_B2C_TENANT}.onmicrosoft.com/${flow}`;

  return {
    names: {
      signUpSignIn: AZURE_AD_B2C_SIGNUP_SIGNIN_FLOW,
      changePassword: AZURE_AD_B2C_CHANGE_PASSWORD_FLOW,
      resetPassword: AZURE_AD_B2C_RESET_PASSWORD_FLOW,
    },
    authorities: {
      signUpSignIn: {
        authority: generateAuthority(
          AZURE_AD_B2C_SIGNUP_SIGNIN_FLOW,
          AZURE_AD_B2C_SIGNUP_SIGNIN_DOMAIN,
        ),
      },
      changePassword: {
        authority: generateAuthority(
          AZURE_AD_B2C_CHANGE_PASSWORD_FLOW,
          AZURE_AD_B2C_SIGNUP_SIGNIN_DOMAIN,
        ),
      },
      resetPassword: {
        authority: generateAuthority(
          AZURE_AD_B2C_RESET_PASSWORD_FLOW,
          AZURE_AD_B2C_SIGNUP_SIGNIN_DOMAIN,
        ),
      },
    },
    authorityDomain:
      AZURE_AD_B2C_SIGNUP_SIGNIN_DOMAIN || `${AZURE_AD_B2C_TENANT}.b2clogin.com`,
  };
};
