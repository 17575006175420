import { ComponentsProps, Theme } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";

import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { getFormatMedia } from "~/theme/utils";

import { COLOR_BLACK } from "../colors";
import { ARIAL_BLACK_FONT_FAMILY } from "../utils/font.constants";

export enum FontWeights {
  fontWeightRegular = 400,
  fontWeightMedium = 700,
  fontWeightBold = 900,
}

export enum TypographyBodyClass {
  price = "price",
  articleLarge = "articleLarge",
  articleLargeBold = "articleLargeBold",
  articleLargeLink = "articleLargeLink",
  articleSmall = "articleSmall",
  articleSmallBold = "articleSmallBold",
  articleSmallLink = "articleSmallLink",
}

export enum TypographyCaptionClass {
  category = "category",
  backToTop = "backToTop",
}

export const getTypographyOptions = (theme: Theme): TypographyOptions => {
  const { BREAKPOINT_TABLET, BREAKPOINT_DESKTOP } = getFormatMedia(theme);

  const boldStyles = {
    "& strong": {
      fontWeight: FontWeights.fontWeightMedium,
    },
  };

  const italicStyles = {
    "& em": {
      fontStyle: "italic",
    },
  };

  return {
    fontFamily: "Arial",
    fontWeightRegular: FontWeights.fontWeightRegular,
    fontWeightMedium: FontWeights.fontWeightMedium,
    fontWeightBold: FontWeights.fontWeightBold,

    allVariants: {
      fontWeight: FontWeights.fontWeightRegular,
      textAlign: "left",
      fontStyle: "normal",
      overflowWrap: "anywhere",
    },
    h1: {
      fontSize: "80px",
      fontWeight: FontWeights.fontWeightMedium,
      lineHeight: "80px",
      letterSpacing: "-0.005em",
      textTransform: "capitalize",
    },
    h1Regular: {
      fontFamily: "Primark Basis, Arial",
      fontSize: "40px",
      fontWeight: FontWeights.fontWeightMedium,
      lineHeight: "48px",
      letterSpacing: "0.005em",

      [BREAKPOINT_TABLET]: {
        fontSize: "48px",
        lineHeight: "56px",
      },

      [BREAKPOINT_DESKTOP]: {
        fontSize: "56px",
        lineHeight: "64px",
      },
    },
    h1Medium: {
      fontFamily: "Primark Basis, Arial",
      fontSize: "24px",
      fontWeight: FontWeights.fontWeightMedium,
      lineHeight: "32px",
      letterSpacing: "0.005em",

      [BREAKPOINT_TABLET]: {
        fontSize: "28px",
        lineHeight: "36px",
      },

      [BREAKPOINT_DESKTOP]: {
        fontSize: "40px",
        lineHeight: "48px",
      },
    },
    h1Small: {
      fontFamily: "Primark Basis, Arial",
      fontSize: "18px",
      fontWeight: FontWeights.fontWeightMedium,
      lineHeight: "24px",
      letterSpacing: "0.005em",
      textTransform: "capitalize",

      [BREAKPOINT_TABLET]: {
        fontSize: "20px",
        lineHeight: "28px",
      },

      [BREAKPOINT_DESKTOP]: {
        fontSize: "24px",
        lineHeight: "32px",
      },
    },
    h1ExtraSmall: {
      fontFamily: "Primark Basis, Arial",
      fontSize: "18px",
      fontWeight: FontWeights.fontWeightRegular,
      lineHeight: "24px",
      letterSpacing: "0.005em",

      [BREAKPOINT_TABLET]: {
        fontSize: "20px",
        lineHeight: "28px",
      },
    },
    h2: {
      fontSize: "56px",
      fontWeight: FontWeights.fontWeightMedium,
      lineHeight: "56px",
      letterSpacing: "0em",
      textTransform: "capitalize",
    },
    h3: {
      fontSize: "40px",
      fontWeight: FontWeights.fontWeightMedium,
      lineHeight: "48px",
      letterSpacing: "0px",
      textTransform: "capitalize",
    },
    h4: {
      fontSize: "26px",
      fontWeight: FontWeights.fontWeightMedium,
      lineHeight: "32px",
      letterSpacing: "0.01em",
      textTransform: "capitalize",
    },
    h5: {
      fontSize: "20px",
      fontWeight: FontWeights.fontWeightMedium,
      lineHeight: "28px",
      letterSpacing: "0.01em",
      textTransform: "capitalize",
    },
    h6: {
      fontSize: "20px",
      lineHeight: "28px",
      letterSpacing: "0.01em",
    },
    button: {
      fontFamily: ARIAL_BLACK_FONT_FAMILY,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.03em",
      textTransform: "uppercase",
      textAlign: "center",
    },
    body1: {
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.02em",

      ...boldStyles,
      ...italicStyles,

      [`&.${TypographyBodyClass.price}`]: {
        fontFamily: ARIAL_BLACK_FONT_FAMILY,
        fontSize: "16px",
        lineHeight: "20px",
        letterSpacing: "0.03em",
        textTransform: "none",
      },

      [`&.${TypographyBodyClass.articleLarge}`]: {
        fontSize: "20px",
        lineHeight: "32px",
        letterSpacing: "0.01em",
      },

      [`&.${TypographyBodyClass.articleLargeBold}`]: {
        fontSize: "20px",
        fontWeight: FontWeights.fontWeightMedium,
        lineHeight: "32px",
        letterSpacing: "0.01em",
      },

      [`&.${TypographyBodyClass.articleLargeLink}`]: {
        fontSize: "20px",
        lineHeight: "32px",
        letterSpacing: "0.01em",
        textDecoration: "underline",
        textDecorationThickness: "1.5px",
        textUnderlineOffset: "2px",
      },

      [`&.${TypographyBodyClass.articleSmall}`]: {
        fontSize: "16px",
        lineHeight: "26px",
        letterSpacing: "0.02em",
      },

      [`&.${TypographyBodyClass.articleSmallBold}`]: {
        fontSize: "16px",
        fontWeight: FontWeights.fontWeightMedium,
        lineHeight: "26px",
        letterSpacing: "0.02em",
      },

      [`&.${TypographyBodyClass.articleSmallLink}`]: {
        fontSize: "16px",
        lineHeight: "26px",
        letterSpacing: "0.02em",
        textDecoration: "underline",
        textUnderlineOffset: "1px",
      },
    },
    body2: {
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.02em",

      ...boldStyles,
      ...italicStyles,
    },
    caption: {
      [`&.${TypographyCaptionClass.category}`]: {
        fontFamily: ARIAL_BLACK_FONT_FAMILY,
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.05em",
        textTransform: "uppercase",
      },
      [`&.${TypographyCaptionClass.backToTop}`]: {
        fontSize: "12px",
        lineHeight: "12px",
        letterSpacing: "0.02em",
        color: COLOR_BLACK,
      },
    },
  };
};

export const getMuiTypographyDefaultProps =
  (): ComponentsProps["MuiTypography"] => ({
    ...(getTestAutomationProps("typography") as ComponentsProps["MuiTypography"]),
    variantMapping: {
      h1Regular: "h1",
      h1Medium: "h1",
      h1Small: "h1",
      h1ExtraSmall: "h1",
    },
  });
